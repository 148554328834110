.user-guide-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .guide-section {
    margin-bottom: 30px;
  }
  
  .guide-section h2 {
    color: #333;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .guide-section p,
  .guide-section ul {
    margin: 10px 0;
    line-height: 1.6;
  }
  
  .guide-section ul {
    padding-left: 20px;
  }
  
  .guide-section li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .user-guide-container {
      padding: 10px;
    }
  }
  