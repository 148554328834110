.App {
  text-align: center;
}

.logo {
  height: 40px; /* Adjust size as needed */
  margin-right: 10px; /* Space between logo and text */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* App.css */
.Home {
  margin: 0;
  padding: 0;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  color: #000000;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  background-size: 400% 400%;
  animation: gradientAnimation 4s ease infinite;
  background-size: 400% 400%;

}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
h1 {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
}



.tagline {
  font-size: 18px;
  font-weight: 400;
margin: 0px;
  text-align: center;
}

.screenshots {
  display: flex;
  justify-content: center;

  margin-top: 60px;
}

.screenshots img {
  width: 400px;
  height: auto;
  border-radius: 8px;

}


.navbar {
  display: flex;
  align-items: center;
  padding: 20px;
   /* adjust as needed */
}

.brand {
  display: flex;
  align-items: center;
}

.brand h1 {
  margin-right: 10px;
  font-size: 24px;
  color: #333; /* adjust as needed */
}

.tagline {
  font-size: 14px;
  color: #777; /* adjust as needed */
}

.hero {
  text-align: center;
  padding: 20px 20px;
  /* adjust as needed */
}

.hero h2 {
  font-size: 48px;
  font-weight: 700;
text-transform: lowercase;
  color: #373636; /* adjust as needed */
}

.screenshots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 00px;
}

.screenshots img {
  margin: 0px;
  max-width: 100%;
  height: auto;
}

.download-link {
  text-align: center;
  margin-top: 30px;
}

.download-link a {
  font-size: 18px;
  color: white;
  text-decoration: none;
}

.download-link a:hover {
  text-decoration: underline;
}


.footer {
  text-align: center;

  padding: 10px;

}

.footer a {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer ul li {
  display: inline; /* Display the list items inline */
  margin-right: 15px; /* Add some space between the links */
}

.footer ul li a {
  text-decoration: none;
 
}

.footer ul li a:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}