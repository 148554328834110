.back-arrow {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
    margin: 10px 0;
    color: #333;
  }
  
  .back-arrow:hover {
    text-decoration: underline;
  }