.faqs-container {
    padding: 0 10px; /* Horizontal padding */
  }
  
  @media (min-width: 768px) {
    .faqs-container {
      padding: 0 150px; /* Horizontal padding for web */
    }
  }
  .faqs-list {
    margin-top: 20px;
  }
  
  .faq-item {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Light border with transparency */
  }
  
  .faq-question {
    cursor: pointer;
    padding: 10px;
    background-color: rgba(249, 249, 249, 0.9); /* Light gray with transparency */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px; /* Optional: adds rounded corners */
  }
  
  .faq-answer {
    padding: 10px;
    background-color: rgba(241, 241, 241, 0.9); /* Slightly darker gray with transparency */
    border-radius: 4px; /* Optional: adds rounded corners */
  }
  
  .toggle-icon {
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7); /* Darker text with transparency */
  }
  