.App {
  text-align: center;
}
.logo-a{
  text-decoration: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
h1 {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
}
body{
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(120deg, #fff5d3 0%, 
  #f8cdc1 100%);
  animation: gradientAnimation 4s ease infinite;
}

#headertext{
line-height: 0;
}
.tagline {
  font-size: 18px;
  font-weight: 400;
margin: 0px;
  text-align: center;
}



.screenshots img {
  width: 150px;
  height: auto;
  border-radius: 8px;

}

.download-link {
  margin-top: 60px;
}

.download-link a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #000000;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-link a:hover {
  background-color: #333333;
}


.footer {
  text-align: center;

  padding: 10px;

}

.footer a {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
